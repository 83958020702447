html{
}

.App {
  text-align: center;
  background-image: url('https://media.wired.com/photos/5d29617806bb5c00088f6596/master/pass/Mars_SS21776893.jpg');
  background-attachment: fixed;
  background-size: cover;
  height: 100vw;

  overflow: scroll;
}

.rover{
  
}

.rover-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: sticky;
  z-index: 10;
} 

.image-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}